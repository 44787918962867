import React, { FC } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import { ArrowShape } from '../../../LoginSocialBar.types';
import { FullArrow, LineArrow } from '../loggedInSocialBar/icons';

interface ArrowProps {
  isNewLoginSocialBarStylesEnabled: boolean;
  shape: ArrowShape;
  className: string;
}

const ArrowShapeMap: Record<
  Exclude<ArrowShape, ArrowShape.None>,
  JSX.Element
> = {
  [ArrowShape.Line]: <LineArrow />,
  [ArrowShape.Full]: <FullArrow />,
};

export const Arrow: FC<ArrowProps> = ({
  className,
  shape,
  isNewLoginSocialBarStylesEnabled,
}) => {
  if (!isNewLoginSocialBarStylesEnabled) {
    return (
      <div className={className}>
        <ArrowIcon />
      </div>
    );
  }

  if (shape === ArrowShape.None) {
    return null;
  }

  return <div className={className}>{ArrowShapeMap[shape]}</div>;
};
