import React, { FC } from 'react';

import AvatarWrapper from '../avatar';
import {
  AvatarWrapperProps,
  ILoginSocialBarProps,
} from '../../../LoginSocialBar.types';
import {
  LOGGED_OUT_TEXT,
  LOGGED_OUT_TEXT_DEFAULT,
  LOGIN_SOCIAL_BAR_NAMESPACE,
} from '../../../constants';
import styles from './LoggedOffSocialBar.scss';

type LoggedOffSocialBarProps = AvatarWrapperProps &
  Pick<ILoginSocialBarProps, 'translate' | 'loggedOutText'> & {
    showAvatar: boolean;
    handleLogin: () => void;
  };

export const LoggedOffSocialBar: FC<LoggedOffSocialBarProps> = ({
  showAvatar,
  handleLogin,
  isLoggedIn,
  avatarUri,
  iconSize,
  defaultAvatarSvgContent,
  displayMode,
  id,
  userName,
  translate,
  loggedOutText,
}) => {
  const translatedLoggedOutText = translate!(
    LOGIN_SOCIAL_BAR_NAMESPACE,
    LOGGED_OUT_TEXT,
    LOGGED_OUT_TEXT_DEFAULT,
  );

  return (
    <button className={styles.login} onClick={handleLogin}>
      {showAvatar && (
        <AvatarWrapper
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          isLoggedIn={isLoggedIn}
          userName={userName}
          avatarUri={avatarUri}
        />
      )}
      <span className={styles.logOutText}>
        {loggedOutText || translatedLoggedOutText}
      </span>
    </button>
  );
};
