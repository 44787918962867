import React, {
  ForwardRefRenderFunction,
  KeyboardEventHandler,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classNames from 'classnames';

import {
  Alignment,
  ILoginSocialBarCustomMenu,
  ILoginSocialBarProps,
} from '../../../LoginSocialBar.types';
import {
  DIRECTION_LEFT_TO_RIGHT,
  DIRECTION_RIGHT_TO_LEFT,
  TEST_ID_CUSTOM_MENU,
  TEST_ID_LINK,
} from '../../../constants';
import {
  activateByEnterButton,
  keyCodes,
} from '../../../../../core/commons/a11y';
import Link from '../../../../Link/viewer/Link';
import styles from './LoginSocialBarCustomMenu.scss';

type ILoginSocialBarCustomMenuProps = Pick<
  ILoginSocialBarProps,
  | 'menuItems'
  | 'onLogout'
  | 'logOutText'
  | 'direction'
  | 'currentPrimaryPageHref'
  | 'menuItemsAlignment'
  | 'isNewLoginSocialBarStylesEnabled'
> & {
  ariaLabel: string;
  open: boolean;
  onClose(): void;
};

const queryFocusableMenuElements = (menuElement: HTMLElement) =>
  Array.from(menuElement.querySelectorAll<HTMLElement>('[tabindex],[href]'));

const LoginSocialBarCustomMenu: ForwardRefRenderFunction<
  ILoginSocialBarCustomMenu,
  ILoginSocialBarCustomMenuProps
> = (
  {
    ariaLabel,
    menuItems,
    logOutText,
    direction,
    currentPrimaryPageHref,
    open,
    onLogout,
    onClose,
    menuItemsAlignment,
    isNewLoginSocialBarStylesEnabled,
  },
  ref,
) => {
  const navRef = useRef<HTMLElement>(null);
  const className = classNames(styles.root, {
    [styles.open]: open,
    [styles.rtl]:
      direction === DIRECTION_RIGHT_TO_LEFT &&
      !isNewLoginSocialBarStylesEnabled,
    [styles.ltr]:
      direction === DIRECTION_LEFT_TO_RIGHT &&
      !isNewLoginSocialBarStylesEnabled,
    [styles.menuLeft]: menuItemsAlignment === Alignment.Left,
    [styles.menuCenter]: menuItemsAlignment === Alignment.Center,
    [styles.menuRight]: menuItemsAlignment === Alignment.Right,
  });

  useImperativeHandle(ref, () => ({
    focusFirstMenuItem: () => {
      if (!navRef.current) {
        return;
      }
      const [firstElement] = queryFocusableMenuElements(navRef.current);
      if (firstElement) {
        firstElement.focus();
      }
    },
  }));

  const handleKeyDown: KeyboardEventHandler = event => {
    const { keyCode } = event;
    const { escape, arrowDown, arrowUp, tab, enter } = keyCodes;
    const focusChangeKeys: Array<number> = [arrowDown, arrowUp, tab];

    if (keyCode === escape) {
      event.preventDefault();
      onClose();
    } else if (keyCode === enter) {
      event.preventDefault();
      const menuItemLink = event.target as HTMLElement;
      menuItemLink.click();
    } else if (focusChangeKeys.includes(keyCode)) {
      event.preventDefault();
      if (!navRef.current) {
        return;
      }

      const focusableElements = queryFocusableMenuElements(navRef.current);
      const currentElement = event.target as HTMLElement;
      const currentElementIndex = focusableElements.indexOf(currentElement);
      if (currentElementIndex === -1) {
        return;
      }

      let nextElementIndex = currentElementIndex;
      if (keyCode === arrowUp) {
        nextElementIndex--;
        if (nextElementIndex < 0) {
          nextElementIndex = focusableElements.length - 1;
        }
      } else {
        nextElementIndex = (nextElementIndex + 1) % focusableElements.length;
      }

      focusableElements[nextElementIndex].focus();
    }
  };

  return (
    <div
      className={classNames({
        [styles.withNewStyles]: isNewLoginSocialBarStylesEnabled,
      })}
    >
      <nav
        ref={navRef}
        className={className}
        aria-label={ariaLabel}
        aria-live="polite"
        data-testid={TEST_ID_CUSTOM_MENU}
        onKeyDown={handleKeyDown}
        onClick={onClose}
      >
        <ul className={styles.menuList}>
          {menuItems.map(({ label, link, displayCount }, index) => (
            <li key={index}>
              <Link
                {...link}
                dataTestId={TEST_ID_LINK}
                className={classNames(styles.link, {
                  [styles.selected]:
                    !!link.href && link.href === currentPrimaryPageHref,
                })}
              >
                <span className={styles.label}>{label}</span>
                {typeof displayCount === 'number' && (
                  <span className={styles.displayCount}>({displayCount})</span>
                )}
              </Link>
            </li>
          ))}
          {!!menuItems.length && (
            <li role="separator">
              <hr />
            </li>
          )}
          <li>
            <div
              className={styles.link}
              tabIndex={0}
              data-testid={TEST_ID_LINK}
              onClick={onLogout}
              onKeyDown={activateByEnterButton}
            >
              <span className={styles.label}>{logOutText}</span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default forwardRef(LoginSocialBarCustomMenu);
